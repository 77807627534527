import React, { useState } from "react";
import {
  useCreateGameTopic,
  useCreateTopicMessage,
  useTopicMessages,
  useUpdatePlayTest,
  useUpdateTopicMessage,
} from "@seabrookstudios/pitch2table";
import { SmartTextField } from "../core/SmartTextField";
import { FullScreenDialog } from "../core/FullScreenDialog";
import Edit from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { ThisUserAvatar } from "../users/UserAvatar";
import CardHeader from "@mui/material/CardHeader";
import { formatTopicDate } from "./model";
import { Editor } from "../core/Editor";
import DeleteForever from "@mui/icons-material/DeleteForever";
import RestoreFromTrash from "@mui/icons-material/RestoreFromTrash";
import { Message } from "./Message";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import { Features } from "../games/features";
import { TopicTypeSelect } from "./TopicTypeSelect";
import { defaultState } from "../core/BlockEditor";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AddTopicToGame = ({ gameId, id }) => {
  const createGameTopic = useCreateGameTopic(gameId);

  const [name, setName] = useState("");
  const [type, setType] = useState(/** @type {import("@seabrookstudios/pitch2table-core").TopicType} */ ("General"));
  const [body, setBody] = useState("");

  const onSaveHandler = async () => {
    await createGameTopic.mutateAsync({
      name,
      body,
      type,
      parent: undefined,
      parentId: undefined,
      implementation: Features.BlockEditor ? "Block" : "HTML",
    });
  };

  return (
    <FullScreenDialog
      id={id}
      title="New Topic"
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      canSave={!name}
      isBusy={createGameTopic.isPending}
    >
      <Box>
        <TopicTypeSelect
          value={type}
          onChange={setType}
          helperText="A broad category for this topic"
          isPending={createGameTopic.isPending}
        />
        <SmartTextField
          id="topic-title"
          label="Title"
          initialValue={name}
          onChange={setName}
          multiline={false}
          borderless
          helperText="Topic Title"
        />
        <Editor value={body} onChange={setBody} />
      </Box>
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").TopicId} props.topicId
 * @param {import("@seabrookstudios/pitch2table-core").MessageId} props.messageId
 * @param {string|import("@seabrookstudios/pitch2table-core").BlockType} props.initialBody
 * @param {import("@seabrookstudios/pitch2table-core").EditorImplementations} props.implementation
 * @param {boolean} props.disabled
 * @param {boolean} props.deleted
 */
export const EditMessage = ({ topicId, messageId, deleted, initialBody, disabled, implementation = "HTML" }) => {
  const updateTopicMessage = useUpdateTopicMessage(topicId, messageId);

  const [body, setBody] = useState(initialBody);

  const onSaveHandlerBlock = async () => {
    await updateTopicMessage.mutateAsync({
      body,
      deleted: undefined,
      implementation: "Block",
    });

    setBody(body);
  };

  const onSaveHandlerHtml = async () => {
    await updateTopicMessage.mutateAsync({
      body,
      deleted: undefined,
      implementation: "HTML",
    });

    setBody(body);
  };

  const deleteMessage = async () => {
    await updateTopicMessage.mutateAsync({
      body: undefined,
      deleted: true,
      implementation,
    });
  };

  const restoreMessage = async () => {
    await updateTopicMessage.mutateAsync({
      body: undefined,
      deleted: false,
      implementation,
    });
  };

  return (
    <FullScreenDialog
      title="Update Message"
      actionText="SAVE"
      onSaveHandler={implementation === "Block" ? onSaveHandlerBlock : onSaveHandlerHtml}
      onDeleteHandler={deleted ? restoreMessage : deleteMessage}
      canSave={false}
      isBusy={updateTopicMessage.isPending}
      Icon={Edit}
      disabled={disabled || deleted}
      DeleteIcon={deleted ? <RestoreFromTrash /> : <DeleteForever />}
      minWidth="66ch"
    >
      <Editor implementation={implementation} value={body} onChange={setBody} />
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").TopicId|null} props.topicId
 */
export const PopUpTopic = ({ topicId }) => {
  const { data: messages = [] } = useTopicMessages(topicId);

  return (
    <FullScreenDialog title="Pitch Deck" canSave={true} Icon={CoPresentIcon} disabled={!topicId}>
      {messages.map((message, i) => (
        <Message key={message.id} message={message} isFirst={i === 0} readOnly />
      ))}
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").TopicId} props.topicId
 * @param {string} props.name
 */
export const AddMessageToTopic = ({ name, topicId }) => {
  const createTopicMessage = useCreateTopicMessage(topicId);

  const [visible, setVisible] = useState(false);
  const [body, setBody] = useState("");

  const onSaveHandler = async () => {
    await createTopicMessage.mutateAsync({
      body,
      implementation: "HTML",
    });

    setBody("");
    setVisible(false);
  };

  return (
    <Card>
      {visible ? (
        <Box p={1}>
          <CardHeader avatar={<ThisUserAvatar />} title={`In response to ${name}`} subheader={formatTopicDate()} />
          <Box p={1} id="comment">
            <Editor value={body} onChange={setBody} />
          </Box>
          <CardActions>
            <Button disabled={body.length === 0} onClick={onSaveHandler}>
              Add comment
            </Button>
          </CardActions>
        </Box>
      ) : (
        <Box p={1} onClick={() => setVisible(true)}>
          <CardHeader avatar={<ThisUserAvatar />} title="Add a comment here..." subheader={formatTopicDate()} />
        </Box>
      )}
    </Card>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestId} props.playTestId
 */
export const AddMessageToPlayTest = ({ gameId, playTestId }) => {
  const updatePlayTest = useUpdatePlayTest(gameId, playTestId);

  const [visible, setVisible] = useState(false);
  const [body, setBody] = useState(Features.BlockEditor ? defaultState : "");

  const onSaveHandler = async () => {
    await updatePlayTest.mutateAsync({
      body,
      audience: undefined,
      complete: undefined,
      date: undefined,
      duration: undefined,
      ignore: undefined,
      medium: undefined,
      rulesVersion: undefined,
      nonPlayerOutcomes: undefined,
      outcomes: undefined,
      implementation: Features.BlockEditor ? "Block" : "HTML",
    });

    setBody(Features.BlockEditor ? defaultState : "");
    setVisible(false);
  };

  return (
    <Card>
      {visible ? (
        <Box p={1}>
          <Box p={1} id="comment">
            <Editor value={body} onChange={setBody} />
          </Box>
          <CardActions>
            <Button disabled={body.length === 0} onClick={onSaveHandler}>
              Add comment
            </Button>
          </CardActions>
        </Box>
      ) : (
        <Box p={1} onClick={() => setVisible(true)}>
          <CardHeader avatar={<ThisUserAvatar />} title="Add a comment here..." subheader={formatTopicDate()} />
        </Box>
      )}
    </Card>
  );
};
