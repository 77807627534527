import React from "react";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

/**
 * @param {object} props
 * @param {import("react-router-dom").To} props.to
 * @param {import("react").ReactNode} [props.children]
 */
export const AppLink = ({ to, children }) => (
  <Link component={RouterLink} underline="hover" color="inherit" to={to}>
    {children}
  </Link>
);

/**
 * @param {object} props
 * @param {import("react-router-dom").To} props.to
 * @param {import("react").ReactNode} [props.children]
 */
export const AlwaysOnAppLink = ({ to, children }) => (
  <Link component={RouterLink} underline="always" color="inherit" to={to}>
    {children}
  </Link>
);

/**
 * @param {object} props
 * @param {string} props.href
 * @param {import("react").ReactNode} [props.children]
 */
export const ExternalLink = ({ href, children }) => (
  <Link color="inherit" href={href} target="_blank" rel="noopener">
    {children}
  </Link>
);
