import React, { useContext } from "react";
import ProtectedRoute from "../App";
import Stack from "@mui/material/Stack";
import UserMenu, { UserMenuTop } from "./UserMenu";
import { AccountContext } from "./AccountProvider";
import { useNavigate } from "react-router-dom";
import { SiteBreadcrumbs } from "./Breadcrumbs";
import { Features } from "../games/features";
import Box from "@mui/material/Box";
import { QuickSearch } from "../QuickSearch";

/**
 * @param {object} props
 * @param {(() => React.JSX.Element)|string|null} [props.UnauthenticatedView]
 * @param {(() => React.JSX.Element)|null} [props.DesignerView]
 * @param {(() => React.JSX.Element)|string|null} [props.PublisherView]
 * @param {import("react").ReactNode} [props.children]
 */
export const AppWithSideMenu = ({
  children,
  UnauthenticatedView = null,
  DesignerView = null,
  PublisherView = null,
}) => {
  const { mode } = useContext(AccountContext);
  const navigate = useNavigate();

  const ViewToDisplay = mode === "Designer" ? DesignerView : PublisherView;
  if (typeof ViewToDisplay === "string") {
    navigate(ViewToDisplay);
    return;
  }

  return (
    <ProtectedRoute UnauthenticatedView={UnauthenticatedView}>
      <QuickSearch>
        <Stack direction="row" id="app-root">
          {Features.BaseCampLayout ? (
            <>
              <Stack direction="column" width="100%" alignItems="center" pt={1}>
                <UserMenuTop />
                <Stack direction="column" width="100%" alignItems="center">
                  <Box width="100%" maxWidth={960} sx={{ padding: { xs: 0.5, md: 2 } }}>
                    <SiteBreadcrumbs />
                    <Stack direction="column">{(ViewToDisplay && <ViewToDisplay />) || children}</Stack>
                  </Box>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <UserMenu />
              <Stack direction="column" width="100%">
                <SiteBreadcrumbs />
                <Stack direction="row">{(ViewToDisplay && <ViewToDisplay />) || children}</Stack>
              </Stack>
            </>
          )}
        </Stack>
      </QuickSearch>
    </ProtectedRoute>
  );
};
