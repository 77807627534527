import React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { enqueueSnackbar } from "notistack";
import { QRCode } from "react-qrcode-logo";
import logo from "../assets/logo.png";
import QrCode2 from "@mui/icons-material/QrCode2";
import ListItemText from "@mui/material/ListItemText";
import { FullScreenDialog } from "../core/FullScreenDialog";
import Download from "@mui/icons-material/Download";
import sanitizeFilename from "sanitize-filename";

/**
 * @param {object} props
 * @param {string} props.url
 * @param {string} props.filename
 */
export const DownloadQrCodeSticker = ({ filename = "", url }) => {
  const downloadQrCode = () => {
    const qrCodeCanvas = /** @type {HTMLCanvasElement} */ (document.getElementById("qrcode"));

    if (!qrCodeCanvas) {
      enqueueSnackbar("Could not download QR code. Please refresh or contact support", { variant: "error" });
      return;
    }

    const fname = sanitizeFilename(filename).length === 0 ? "qrcode.png" : sanitizeFilename(filename);

    const link = document.createElement("a");
    link.href = qrCodeCanvas.toDataURL();
    link.download = fname;
    link.click();
  };

  return (
    <ListItem>
      <ListItemText primary="QR Code" secondary="Stick this on your prototype box to quickly access this game." />
      <FullScreenDialog
        title="Game QR Code (45x45mm)"
        Icon={QrCode2}
        DeleteIcon={<Download />}
        onDeleteHandler={downloadQrCode}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <QRCode
            id="qrcode"
            size={531}
            logoHeight={117}
            logoWidth={117}
            qrStyle="squares"
            removeQrCodeBehindLogo={false}
            value={url}
            logoImage={logo}
            fgColor="#396EA5"
          />
        </Box>
      </FullScreenDialog>
    </ListItem>
  );
};
