import { useCallback, useMemo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * @template T
 * @param {import("@tanstack/react-query").UseQueryResult<T[]>} query
 * @param {Filter<T>[]} allFilters
 * @param {string} param when loading filter from query string, what key
 * @returns {Filters<T>}
 */
export const useFiltersv2 = (query, allFilters, param) => {
  /**
   * @type {[T[],import('react').React.Dispatch<import('react').React.SetStateAction<T[]>>]}
   */
  const [filtered, setFiltered] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [done, setDone] = useState(false);
  const [filter, setFilter] = useState(`${searchParams.get(param)}`.toUpperCase());
  const [visibleFilters, setVisibleFilters] = useState([]);

  const { data: allItems = [], isLoading } = query;

  /**
   * @param {string} label
   */
  const onFilterClicked = useCallback(
    (label) => {
      searchParams.set(param, label);
      setSearchParams(searchParams);
      setFilter(label);
    },
    [param, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (query.isError) {
      return;
    }
    if (allItems.length === 0) {
      setFiltered(allItems);
      return;
    }

    const appliedFilter = allFilters.find(({ label }) => label === filter);
    if (!appliedFilter) {
      setFiltered(allItems);
      return;
    }

    const toShow = allItems.filter((t) => appliedFilter.filter(t));
    if (toShow.length === 0) {
      setFilter("ALL");
      setFiltered(allItems);
      searchParams.set(param, "ALL");
      return;
    }

    setFiltered(toShow);
  }, [allItems, isLoading, filter, allFilters, param, searchParams, query.isError]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (query.isError) {
      return;
    }
    if (allFilters.length === 0) {
      return;
    }

    const withCount = allFilters
      .map((filter) => ({
        ...filter,
        count: allItems.filter((t) => filter.filter(t)).length,
      }))
      .filter(({ count, label }) => count > 0 || label === "ALL")
      .filter(({ count, label }) => count !== allItems.length || label === "ALL");

    setVisibleFilters(withCount);

    if (done && !!searchParams.get(param)) {
      return;
    }
    if (done && !searchParams.get(param)) {
      const sorted = [...withCount];
      sorted.sort((a, b) => b.weight - a.weight);

      setFilter(sorted[0].label);
      searchParams.set(param, sorted[0].label);
      setSearchParams(searchParams);
    }

    if (!done && allItems.length > 0) {
      const sorted = [...withCount];
      sorted.sort((a, b) => b.weight - a.weight);

      if (!searchParams.get(param)) {
        setFilter(sorted[0].label);
        searchParams.set(param, sorted[0].label);
        setSearchParams(searchParams);
      }
      setDone(true);
    }
  }, [allItems, isLoading, query.isError, done, searchParams, param, allFilters, setSearchParams]);

  const value = useMemo(
    () => ({
      filtered,
      filter,
      visibleFilters: visibleFilters.length > 1 ? visibleFilters : [],
      onFilterClicked,
    }),
    [filtered, filter, onFilterClicked, visibleFilters]
  );

  return value;
};
