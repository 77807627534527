import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {
  useContact,
  useGame,
  useMyDocumentsRelatingToPublisher,
  usePublisherAndContacts,
} from "@seabrookstudios/pitch2table";
import { Path } from "../Path";
import { AppLink } from "../styleguide/AppLink";
import { useMatches, useParams, useSearchParams } from "react-router-dom";
import { ChangeLayoutButtonSmall } from "./ChangeLayoutButton";
import { useAttachmentIdFromRoute, usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";
import { Features } from "../games/features";
import { usePublisherName } from "../publishers/model";

export const MyGames = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("games")) {
      searchParams.append("games", "ALL");
    }
  }, [searchParams]);

  return <AppLink to={Path.games()}>{searchParams.get("games")}</AppLink>;
};

export const PublisherInterestFilter = ({ gameId }) => {
  const [searchParams] = useSearchParams();

  return <AppLink to={Path.game(gameId).publishers()}>{searchParams.get("publisher-interest")}</AppLink>;
};

export const MyPublishers = () => <AppLink to={Path.publishers()}>My Publishers</AppLink>;
export const MyPublisherDocuments = () => {
  const publisherId = usePublisherIdFromRoute();

  return <AppLink to={Path.publisher(publisherId).documents()}>Documents</AppLink>;
};

const Settings = () => <AppLink to={Path.settings().none()}>Settings</AppLink>;

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const GamesBreadcrumb = ({ gameId }) => {
  const { data: game, isLoading } = useGame(gameId);

  const text = game ? game.name : "Error";

  return <AppLink to={Path.game(gameId).link()}>{isLoading ? <Skeleton /> : text}</AppLink>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const MyPublisherNameBreadcrumb = ({ publisherId }) => {
  const [isLoading, text] = usePublisherName(publisherId);

  return <AppLink to={Path.publisher(publisherId).link()}>{isLoading ? <Skeleton /> : text}</AppLink>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const MyDocumentNameBreadCrumb = () => {
  const publisherId = usePublisherIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();

  const { data: documents = [], isLoading } = useMyDocumentsRelatingToPublisher(publisherId);
  const contract = documents.find((c) => c.id === attachmentId);

  return (
    <AppLink to={Path.publisher(publisherId).document(attachmentId)}>
      {isLoading || !contract ? <Skeleton /> : contract.name}
    </AppLink>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const GameComponentConfigurationsBreadcrumb = ({ gameId }) => {
  return <AppLink to={Path.game(gameId).components()}>Components</AppLink>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const GameLanesBreadcrumb = ({ gameId }) => {
  return <AppLink to={Path.game(gameId).lanes()}>Lanes</AppLink>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {string} props.configurationId
 */
export const GameComponentConfigurationQuotesBreadcrumb = ({ gameId, configurationId }) => {
  return <AppLink to={Path.game(gameId).component(configurationId).quotes()}>Quotes</AppLink>;
};

/**
 * @deprecated
 * @param {string} name name of breadcrumb
 * @param {(props) => import("react-router-dom").To} path path to show
 * @param {((props) => React.JSX.Element)[]} priors
 * @param {((props) => React.JSX.Element)} [Crumb]
 */
export const makeBreadcrumbs = (name, path, priors = [], Crumb = undefined) => {
  /**
   * @param {object} props
   */
  const Bread = (props) => {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {priors.map((Prior) => {
          return <Prior {...props} />;
        })}
        {Crumb ? <Crumb {...props} /> : <AppLink to={path(props)}>{name}</AppLink>}
      </Breadcrumbs>
    );
  };

  return Bread;
};

/**
 * @deprecated
 * @param {((props) => React.JSX.Element)[]} priors
 * @param {((props) => React.JSX.Element)} Crumb
 */
export const makeBreadcrumbs2 = (priors = [], Crumb) => {
  /**
   * @param {object} props
   */
  const Bread = (props) => {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {priors.map((Prior) => {
          return <Prior {...props} />;
        })}
        <Crumb {...props} />
      </Breadcrumbs>
    );
  };

  return Bread;
};

/**
 * @param {((props) => React.JSX.Element)[]} all
 */
export const makeBreadcrumbs3 = (all = []) => {
  /**
   * @param {object} props
   */
  const Bread = (props) => {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {all.map((Crumb, i) => {
          return <Crumb key={i} {...props} />;
        })}
      </Breadcrumbs>
    );
  };

  return Bread;
};

/**
 * @param {((props: *) => React.JSX.Element)[]} all
 */
export const makeBreadcrumbs4 = (all = []) => {
  /**
   * @param {object} props
   */
  const Bread = (props) => {
    return all.map((Crumb) => {
      return <Crumb {...props} />;
    });
  };

  return Bread;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {string} props.configurationId
 */
export const GameComponentConfigurationQuotesioBreadcrumb = ({ gameId, configurationId }) => {
  return <AppLink to={Path.game(gameId).component(configurationId).quotes()}>Quotes</AppLink>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/types").UserId} props.userId
 */
const GameDesignerBreadcrumb = ({ gameId, userId }) => {
  const { data: contact, isLoading } = useContact(userId);

  const text = contact ? `${contact.firstName} ${contact.lastName}` : "Error";

  return <AppLink to={Path.game(gameId).designer(userId)}>{isLoading ? <Skeleton /> : text}</AppLink>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
const GamePublisherBreadcrumb = ({ gameId, publisherId }) => {
  const { data, isLoading } = usePublisherAndContacts(publisherId);

  if (isLoading) {
    return (
      <AppLink to={Path.game(gameId).publisher(publisherId)}>
        <Skeleton />
      </AppLink>
    );
  }

  if (!data) {
    return <AppLink to={Path.game(gameId).publisher(publisherId)}>Error</AppLink>;
  }

  const [publisher] = data;
  if (!publisher) {
    return <AppLink to={Path.game(gameId).publisher(publisherId)}>Error</AppLink>;
  }

  return <AppLink to={Path.game(gameId).publisher(publisherId)}>{publisher.name}</AppLink>;
};

export const SettingsBreadcrumbs = () => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Settings />
    </Breadcrumbs>
  );
};

/**
 * @typedef {('about-you'|'about-publisher'|'contact'|'your-location'|'invites'|'notifications')} SettingCategory
 */

/**
 * @type {Record<SettingCategory,string?>}
 */
const SettingCategoryLabels = {
  "about-you": "About You",
  "about-publisher": "About Publisher",
  invites: "Invites",
  contact: "Publisher Contact",
  "your-location": "Location",
  notifications: "Notifications",
};

/**
 * @param {object} props
 * @param {SettingCategory} props.category
 */
const SettingsCategory = ({ category }) => (
  <AppLink to={Path.settings().category(category)}>{SettingCategoryLabels[category]}</AppLink>
);

/**
 * @param {object} props
 * @param {SettingCategory} props.category
 */
export const SettingsCategoryBreadcrumbs = ({ category }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Settings />
      <SettingsCategory category={category} />
    </Breadcrumbs>
  );
};

export const MyGamesBreadcrumbs = () => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {/* <MyGames /> */}
    </Breadcrumbs>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const SelectedGameBreadcrumbs = ({ gameId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
    </Breadcrumbs>
  );
};
/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const SelectedGamePublisherBreadcrumbs = ({ gameId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <PublisherInterestFilter gameId={gameId} />
    </Breadcrumbs>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const SelectedGameSellsheetsBreadcrumbs = ({ gameId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <AppLink to={Path.game(gameId).sellsheets()}>Sellsheets</AppLink>
    </Breadcrumbs>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const SelectedGameDesignerBreadcrumbs = ({ gameId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <AppLink to={Path.game(gameId).designers()}>Designers</AppLink>
    </Breadcrumbs>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/types").UserId} props.userId
 */
export const SelectedGameSelectedDesignerBreadcrumbs = ({ gameId, userId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <AppLink to={Path.game(gameId).designers()}>Designers</AppLink>
      <GameDesignerBreadcrumb gameId={gameId} userId={userId} />
    </Breadcrumbs>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const SelectedGameRulesBreadcrumbs = ({ gameId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <AppLink to={Path.game(gameId).rules()}>Rules</AppLink>
    </Breadcrumbs>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const SelectedGameContractsBreadcrumbs = ({ gameId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <AppLink to={Path.game(gameId).contracts()}>Contracts</AppLink>
    </Breadcrumbs>
  );
};

/**
 * @param {string} name name of breadcrumb
 * @param {(gameId: import("@seabrookstudios/types").GameId) => import("react-router-dom").To} path path to show
 */
export const makeGameSubsection = (name, path) => {
  /**
   * @param {object} props
   * @param {import("@seabrookstudios/types").GameId} props.gameId
   */
  const GameSubsectionBreadcrumbs = ({ gameId }) => {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <MyGames />
        <GamesBreadcrumb gameId={gameId} />
        <AppLink to={path(gameId)}>{name}</AppLink>
      </Breadcrumbs>
    );
  };

  return GameSubsectionBreadcrumbs;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const SelectedGamePublisherInterestBreadcrumbs = ({ gameId, publisherId }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <MyGames />
      <GamesBreadcrumb gameId={gameId} />
      <PublisherInterestFilter gameId={gameId} />
      <GamePublisherBreadcrumb gameId={gameId} publisherId={publisherId} />
    </Breadcrumbs>
  );
};

export const SiteBreadcrumbs = () => {
  const params = useParams();
  const matches = useMatches();
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle.crumb(params));
  const quickLinks = matches
    .filter((match) => Boolean(match.handle?.quickLinks))
    .map((match) => match.handle?.quickLinks);

  const Crumbs = crumbs[0];
  const QuickLinks = quickLinks[0];

  if (!Crumbs) {
    return null;
  }

  if (Features.BaseCampLayout) {
    return (
      <Box sx={{ pl: { xs: 4, md: 8 }, pr: { xs: 4, md: 8 } }}>
        <Card style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} variant="outlined" elevation={0}>
          <Stack
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            justifyContent="space-between"
            alignItems="center"
            minHeight={58}
          >
            <Box pl={1} pt={1} pb={1} flex={1}>
              {Crumbs}
            </Box>
            {QuickLinks && (
              <Box flex={1}>
                <QuickLinks />
              </Box>
            )}
          </Stack>
        </Card>
      </Box>
    );
  }

  return (
    <Box pl={2} pr={2}>
      <Card>
        <Stack direction="row" justifyContent="space-between">
          <Box pl={1} pt={1} pb={1}>
            {Crumbs}
          </Box>
          {!Features.BaseCampLayout && <ChangeLayoutButtonSmall />}
        </Stack>
      </Card>
    </Box>
  );
};
