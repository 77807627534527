import React, { useContext, useEffect, useState } from "react";
import Image from "mui-image";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import {
  useCreateUnlinkedPublisher,
  useGamePublishers,
  useMyUnlinkedPublishers,
  useShortlistPublisher,
} from "@seabrookstudios/pitch2table";
import LinkOff from "@mui/icons-material/LinkOff";
import { enqueueSnackbar } from "notistack";
import { SmartTextField } from "../../core/SmartTextField";
import { useWindowSize } from "usehooks-ts";
import { ShortlistPublisherFilterContext, ShortlistPublisherFilterProvider } from "./ShortlistPublisherFilter";
import Clear from "@mui/icons-material/Clear";

const ShortlistLimit = 10;

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {Function} props.onPublisherSelected
 */
const PublisherList = ({ gameId, onPublisherSelected }) => {
  const { height } = useWindowSize();

  const { filter, setFilter } = useContext(ShortlistPublisherFilterContext);
  const { data: publishers = [], isLoading } = useMyUnlinkedPublishers();
  const { data: existingPublishers = [] } = useGamePublishers(gameId);
  const [checked, setChecked] = React.useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PublisherId[]} */ ([])
  );

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
   */
  const handleToggle = (publisherId) => () => {
    setChecked((current) => {
      if (current.includes(publisherId)) {
        return current.filter((id) => id !== publisherId);
      }

      return [...current, publisherId];
    });
  };

  const updateFilter = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    onPublisherSelected(checked);
  }, [checked, onPublisherSelected]);

  useEffect(() => {
    if (checked.length === ShortlistLimit) {
      enqueueSnackbar(`A maximum of ${ShortlistLimit} publishers can be shortlisted at a time`, {
        variant: "info",
        autoHideDuration: 3000,
      });
    }
  }, [checked]);

  return (
    <List style={{ height: height - 40 - 56, overflow: "auto" }}>
      <Box p={1}>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="shortlist-publisher-filter"></InputLabel>
          <OutlinedInput
            fullWidth
            id="shortlist-publisher-filter"
            value={filter}
            onChange={updateFilter}
            endAdornment={
              <InputAdornment position="end">
                {filter.length > 0 && (
                  <IconButton onClick={() => setFilter("")}>
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      {isLoading && <CircularProgress />}
      {publishers
        .filter((p) => !existingPublishers.map((ep) => ep.publisherId).includes(p.id))
        .filter((p) => p.name.toLowerCase().includes(filter.toLowerCase()))
        .map((publisher) => (
          <ListItem
            key={publisher.id}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(publisher.id)}
                checked={checked.includes(publisher.id)}
                disabled={!checked.includes(publisher.id) && checked.length >= ShortlistLimit}
              />
            }
          >
            <ListItemIcon>
              <Image
                src={publisher.logoUrl}
                alt={`Company logo for ${publisher.name}`}
                fit="contain"
                width={32}
                height={32}
                errorIcon={<LinkOff />}
              />
            </ListItemIcon>
            <ListItemText
              primary={publisher.name}
              secondary={publisher.alternateNames.length > 0 ? `AKA ${publisher.alternateNames.join(", ")}` : ""}
            />
          </ListItem>
        ))}
    </List>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AddPublisherInterest = ({ gameId }) => {
  const [selected, setSelected] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PublisherId[]} */ ([])
  );

  const shortlistPublishers = useShortlistPublisher(gameId);

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId[]} publisherIds
   */
  const updatedSelectedPublishers = (publisherIds) => {
    setSelected(publisherIds);
  };

  const onAction = async () => {
    if (selected.length === 0) {
      return;
    }

    await shortlistPublishers.mutateAsync({ publisherIds: selected });
  };

  const [newPublisherName, setNewPublisherName] = useState("");
  const clearName = () => {
    setNewPublisherName("");
  };
  const create = useCreateUnlinkedPublisher(clearName);
  const recordPublisherName = (text) => {
    setNewPublisherName(text);
  };

  const addCustomPublisher = () => {
    if (!newPublisherName) {
      return;
    }

    create.mutate({ name: newPublisherName });
  };

  return (
    <FullScreenDialog
      title="Shortlist Publishers"
      actionText={selected.length !== 1 ? `SHORTLIST ${selected.length} PUBLISHERS` : "SHORTLIST PUBLISHER"}
      canSave={selected.length === 0}
      onSaveHandler={onAction}
      isBusy={shortlistPublishers.isPending}
      id="add-shortlist-publisher"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card elevation={2}>
            <ShortlistPublisherFilterProvider>
              <PublisherList gameId={gameId} onPublisherSelected={updatedSelectedPublishers} />
            </ShortlistPublisherFilterProvider>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card elevation={2}>
            <CardContent>
              <Typography>You're ready to send your game to a publisher. Great news!</Typography>
              <Typography pt={1}>
                The first step is shortlist a set of publishers you wish to contact. From this shortlist you'll be able
                to record when you have contacted them. You may select at most 10 publishers to shortlist at a time.
              </Typography>
              <Typography pt={1}>
                If you can't find a publisher, you can add them using the form below. We'll checked added publishers and
                will add their logo and company details if we find them. When we do we'll make the publisher public for
                all users. Until then, added publishers are only visible to you and your co-designers.
              </Typography>
            </CardContent>
          </Card>
          <Box p={0.5} />
          <Card elevation={2}>
            <CardHeader avatar={<LinkOff />} title="Custom Publishers" />
            <CardContent>
              <SmartTextField
                required={false}
                value={newPublisherName}
                label="Publisher Name"
                helperText=""
                autoFocus={false}
                onChange={setNewPublisherName}
                onEnter={recordPublisherName}
              />
            </CardContent>
            <CardActions>
              <Button disabled={newPublisherName.length === 0} onClick={addCustomPublisher}>
                {create.isPending ? <CircularProgress size={16} /> : "Add Publisher"}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </FullScreenDialog>
  );
};
