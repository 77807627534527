import React from "react";
import { formatMessageDate } from "./model";
import parse from "html-react-parser";
import Box from "@mui/material/Box";
import { EditMessage } from "./AddTopic";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { UserAvatar } from "../users/UserAvatar";
import ListItemText from "@mui/material/ListItemText";
import { options } from "../core/Editor";
import { useUserProfile } from "../user/queries";
import Typography from "@mui/material/Typography";
import { ReadOnlyEditor } from "../core/BlockEditor";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").BlockMessage} props.message
 */
const MessageDisplayBlock = ({ message }) => {
  return <ReadOnlyEditor value={message.body} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Message} props.message
 */
const MessageDisplay = ({ message }) => {
  if (message.implementation === "Block") {
    return <MessageDisplayBlock message={message} />;
  }
  if (message.deleted) {
    return (
      <Typography color="text.secondary" style={{ fontStyle: "italic" }}>
        {parse(message.body, options)}
      </Typography>
    );
  }

  return parse(message.body, options);
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Message} props.message
 * @param {boolean} props.isFirst
 * @param {boolean} [props.readOnly]
 */
export const Message = ({ message, isFirst, readOnly = false }) => {
  const { data: profile } = useUserProfile();

  const canEditMessage = profile ? profile.id === message.lastModifiedByUserId : false;

  return (
    <Box>
      {!isFirst && (
        <Box p={4}>
          <Divider />
        </Box>
      )}
      <List>
        <ListItem
          secondaryAction={
            <EditMessage
              disabled={!canEditMessage || readOnly}
              initialBody={message.body}
              messageId={message.id}
              topicId={message.topicId}
              deleted={message.deleted}
              implementation={message.implementation}
            />
          }
        >
          <ListItemAvatar>
            <UserAvatar userId={message.lastModifiedByUserId} />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessageDate(message.created)}
            secondary={message.created !== message.updated ? "Edited" : ""}
          />
        </ListItem>
      </List>
      <MessageDisplay message={message} />
    </Box>
  );
};
