import { optionsAuth } from "@seabrookstudios/auth";
import endpoints from "../endpoints";
import { get, post } from "../network";

/**
 * @param {import('axios').AxiosRequestConfig} options,
 * @param {('v1'|'v2')} version
 * @returns {import('axios').AxiosRequestConfig}
 */
const optionsVersion = (options, version) => {
  return {
    ...options,
    headers: {
      ...options.headers,
      version,
    },
  };
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").AdminOnlyPublicContact>}
 */
export const fetchThisUser = async (token) => {
  const { users } = endpoints();
  const url = users().meNew();

  const response = await get(url, optionsVersion(optionsAuth(token), "v2"));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/types").UserId} userId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").PublicContact>}
 */
export const fetchContact = async (token, userId) => {
  const { contacts } = endpoints();
  const url = contacts().get(userId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} preferredName
 * @param {string} pronouns
 * @param {string} contactNumber
 * @param {string} city
 * @param {string} country
 * @param {string} timezone
 * @param {import("@seabrookstudios/pitch2table-core").Currency|undefined} preferredCurrency
 * @returns {Promise<>}
 */
const updateMyDetails = async (
  token,
  firstName = "",
  lastName = "",
  preferredName = "",
  pronouns = "",
  contactNumber = "",
  city = "",
  country = "",
  timezone = "",
  preferredCurrency = undefined
) => {
  const { users } = endpoints();
  const url = users().me();

  /**
   * @type {import("@seabrookstudios/pitch2table-core").UpdateUserRequest}
   */
  const body = {
    firstName,
    lastName,
    preferredName,
    pronouns,
    contactNumber,
    city,
    country,
    timezone,
    preferredCurrency,
  };

  const response = await post(url, body, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} firstName
 * @returns {Promise<>}
 */
export const updateFirstName = async (token, firstName) => {
  return updateMyDetails(token, firstName);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} lastName
 * @returns {Promise<>}
 */
export const updateLastName = async (token, lastName) => {
  return updateMyDetails(token, "", lastName);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} preferredName
 * @returns {Promise<>}
 */
export const updatePreferredName = async (token, preferredName) => {
  return updateMyDetails(token, "", "", preferredName);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} pronouns
 * @returns {Promise<>}
 */
export const updatePronouns = async (token, pronouns) => {
  return updateMyDetails(token, "", "", "", pronouns);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} contactNumber
 * @returns {Promise<>}
 */
export const updateContactNumber = async (token, contactNumber) => {
  return updateMyDetails(token, "", "", "", "", contactNumber);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} city
 * @returns {Promise<>}
 */
export const updateCity = async (token, city) => {
  return updateMyDetails(token, "", "", "", "", "", city);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} country
 * @returns {Promise<>}
 */
export const updateCountry = async (token, country) => {
  return updateMyDetails(token, "", "", "", "", "", "", country);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} timezone
 * @returns {Promise<>}
 */
export const updateTimezone = async (token, timezone) => {
  return updateMyDetails(token, "", "", "", "", "", "", "", timezone);
};
