import React from "react";
import { useGamePlayTests, usePlayTests } from "@seabrookstudios/pitch2table";
import { NoPlayTests, NoPlayTestsNoMetadata } from "./Empty";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { AddPlayTestToGame } from "./Add";
import { Path } from "../Path";
import { PlayTestListEntry, PlayTestMetaData, PlayTestSummaryListEntry } from "./ListEntry";
import { PlayTestFromRoute } from "./FromRoute";
import { PlayTestFilters } from "./filters";
import Stack from "@mui/material/Stack";
import { ImportPlayTestsToGame } from "./ImportPlayTests";

export const PlayTestListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const Action = () => (
    <Stack direction="row">
      <ImportPlayTestsToGame id="import-play-tests" gameId={gameId} />
      <AddPlayTestToGame id="add-play-test" gameId={gameId} />
    </Stack>
  );

  const useMakeList = useMakeListBuilder(
    PlayTestListEntry,
    idExtractor,
    "Play Tests",
    NoPlayTests,
    Action,
    PlayTestMetaData,
    Path.game(gameId).link()
  );

  const useWrappedQuery = () => useGamePlayTests(gameId);

  const ListAndDetail = useMakeListAndDetail(
    useWrappedQuery,
    PlayTestFilters,
    "play-tests",
    useMakeList,
    PlayTestFromRoute
  );

  return <ListAndDetail />;
};

export const AllPlayTestListAndDetail = () => {
  const useMakeList = useMakeListBuilder(
    PlayTestSummaryListEntry,
    idExtractor,
    "Play Tests",
    NoPlayTestsNoMetadata,
    undefined,
    undefined,
    Path.playTests()
  );

  const ListAndDetail = useMakeListAndDetail(usePlayTests, [], "all-play-tests", useMakeList, PlayTestFromRoute);

  return <ListAndDetail />;
};
