import React, { useEffect, useState } from "react";
import { useGamePlayTests, useGameTopic, useTopicMessages, useUpdateGameTopic } from "@seabrookstudios/pitch2table";
import Card from "@mui/material/Card";
import { useGameIdFromRoute, useTopicIdFromRoute } from "../games/WithGameIdFromRoute";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import Skeleton from "@mui/material/Skeleton";
import { formatTopicDate } from "./model";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { EditableCardHeader } from "../games/EditableHeading";
import { AddMessageToTopic } from "./AddTopic";
import ListItemText from "@mui/material/ListItemText";
import { useUserProfile } from "../user/queries";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Refresh from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { TopicTypeSelect } from "./TopicTypeSelect";
import { useDocumentTitle } from "usehooks-ts";
import { AlwaysOnAppLink } from "../styleguide/AppLink";
import { Path } from "../Path";
import { Message } from "./Message";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").TopicId} props.topicId
 * @param {boolean} props.hideType
 * @param {boolean} props.hideTitle
 * @param {boolean} props.hideDate
 * @param {string} [props.customTitle]
 */
export const TopicDisplay = ({ gameId, topicId, hideType, hideTitle, hideDate, customTitle }) => {
  const { data: topic } = useGameTopic(gameId, topicId);
  const { data: messages = [], refetch, isRefetching } = useTopicMessages(topicId);
  const updateGameTopic = useUpdateGameTopic(gameId, topicId);
  const { data: profile } = useUserProfile();
  const { data: playTests = [] } = useGamePlayTests(gameId);
  const [playTest, setPlayTest] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PlayTest|undefined} */ (undefined)
  );

  useEffect(() => {
    const playTestForTopic = playTests.find((pt) => pt.topicId === topicId);

    setPlayTest(playTestForTopic);
  }, [playTests, topicId]);

  useDocumentTitle(topic ? topic.name : "Pitch2Table");

  if (!topicId) {
    return <EmptyDetailCard text="Select a topic from the list on the left." />;
  }

  /**
   * @param {string} name
   */
  const updateTitle = async (name) => {
    await updateGameTopic.mutateAsync({
      name,
      type: undefined,
      archived: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").TopicType} type
   */
  const updateType = async (type) => {
    await updateGameTopic.mutateAsync({
      name: undefined,
      type,
      archived: undefined,
    });
  };

  const canEditTopic = profile && topic && profile.id === topic.lastModifiedByUserId;

  return (
    <Box>
      <Card elevation={1} square={false}>
        {!hideTitle && (
          <Box display="flex">
            <Box flex={1}>
              {topic ? (
                <EditableCardHeader
                  text={customTitle || topic.name}
                  subheader={hideDate ? "" : topic ? formatTopicDate(topic.created) : <Skeleton />}
                  helperText=""
                  title=""
                  onSave={updateTitle}
                  disabled={!canEditTopic || !!customTitle}
                />
              ) : (
                <Skeleton />
              )}
            </Box>
            <Box flex={0}>
              <IconButton
                onClick={refetch}
                style={{ justifyContent: "center", alignItems: "center", alignSelf: "flex-end" }}
              >
                {isRefetching ? <CircularProgress size={16} /> : <Refresh />}
              </IconButton>
            </Box>
          </Box>
        )}
        {!hideType && (
          <Box pl={2} pr={2}>
            {playTest ? (
              <ListItemText
                primary="Play Test"
                secondary={
                  <Typography variant="inherit" color="text.secondary">
                    This topic belongs to a play test.{" "}
                    <AlwaysOnAppLink to={Path.game(gameId).test(playTest.id)}>Click to visit</AlwaysOnAppLink>
                  </Typography>
                }
              />
            ) : (
              <TopicTypeSelect
                disabled={!topic}
                value={topic ? topic.type : "General"}
                onChange={updateType}
                helperText="A broad category for this topic"
                isPending={updateGameTopic.isPending}
              />
            )}
          </Box>
        )}
        <CardContent>
          {messages.map((message, i) => (
            <Message key={message.id} message={message} isFirst={i === 0} />
          ))}
        </CardContent>
      </Card>
      <Box p={1} />
      <AddMessageToTopic name={topic ? topic.name : ""} topicId={topicId} />
    </Box>
  );
};

export const TopicFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const topicId = useTopicIdFromRoute();

  return <TopicDisplay gameId={gameId} topicId={topicId} hideType={false} hideTitle={false} hideDate={false} />;
};
