import React from "react";
import ReactQuill from "react-quill";
import "./editor.css";

/**
 * to support images we would need to:
 *  a) capture the image, upload it and then insert a reference
 *  b) only allow inserting of previously uploaded images
 */

export const HtmlEditor = ({ value, onChange }) => {
  return (
    <div data-text-editor="name">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(text, ...x) => {
          onChange(text, ...x);
        }}
        bounds={`[data-text-editor="name"]`}
        modules={{
          toolbar: [
            [{ header: 1 }, { header: 2 }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            // [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={["header", "bold", "italic", "strike", "blockquote", "underline", "list", "bullet", "link", "indent"]}
      />
    </div>
  );
};
