import React, { useContext, useEffect } from "react";
import { Path } from "./Path";
import { AddToSubmissionsLandingPage } from "./landing-pages/add-to-submissions";
import { AddToSubmissionsLandingPageLoggedIn } from "./landing-pages/add-to-submissions-logged-in";
import { AppWithSideMenu } from "./core/AppWithSideMenu";
import { LogoutScreen } from "./login/LogoutButton";
import { SelectAccountPage } from "./core/Switcher";
import AccountProvider from "./core/AccountProvider";
import { ErrorPage } from "./core/ErrorPage";
import { LoginPage } from "./auth/LoginPage";
import { routes as gameRoutes } from "./games/routes";
import { routes as settingsRoutes } from "./settings/routes";
import { routes as publicRoutes } from "./public/routes";
import { routes as publishersRoutes } from "./publishers/routes";
import { routes as documentRoutes } from "./documents/routes";
import { routes as royaltyRoutes } from "./royalties/routes";
import { routes as messageBoardRoutes } from "./message-board/routes";
import { routes as playTestRoutes } from "./play-tests/routes";
import { GetMobilePage } from "./general/GetMobilePage";
import { FeedbackAndSupportPage } from "./general/FeedbackAndSupportPage";
import { UpdatesPage } from "./general/UpdatesPage";
import { Navigate, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Auth0Stack } from "@seabrookstudios/auth-web";
import CircularProgress from "@mui/material/CircularProgress";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { CenterOnPage } from "./core/CenterOnPage";

const doesntSupportPublisherView = [
  {
    path: Path.Landing.AddToSubmissionsBase,
    element: <AddToSubmissionsLandingPage />,
  },
  {
    path: Path.Landing.AddToSubmissionsBaseLoggedIn,
    element: (
      <AppWithSideMenu>
        <AddToSubmissionsLandingPageLoggedIn />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/contacts",
    element: <AppWithSideMenu>{/* <ContactListWithDetail /> */}</AppWithSideMenu>,
  },
];

export const AuthedRoute = () => {
  const prod = "XFylpydHLSsmQ9mTeJTMfrub9JP9G7pk";
  const dev = "pwjI0u61Ntwx9tQjuUDHtOpp0B76oz63";
  const prodCallback = "https://app.pitch2table.com/callback";
  const devCallback = "http://localhost:3000/callback";

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Stack
      domain="login.pitch2table.com"
      clientId={process.env.NODE_ENV === "production" ? prod : dev}
      redirectUri={process.env.NODE_ENV === "production" ? prodCallback : devCallback}
      audience="https://secure.pitch2table.com"
      onRedirectCallback={onRedirectCallback}
    >
      <AccountProvider>
        <Outlet />
      </AccountProvider>
    </Auth0Stack>
  );
};

const WaitForSuccessfulLogin = () => {
  const navigate = useNavigate();

  const token = useContext(CachedTokenContext);

  useEffect(() => {
    if (!token) {
      return;
    }

    navigate(Path.games());
  });

  return (
    <CenterOnPage>
      <CircularProgress />
    </CenterOnPage>
  );
};

/**
 * @type {import("react-router-dom").RouteObject[]}
 */
const mainRoutes = [
  {
    path: "/",
    element: <Navigate to={Path.Login} />,
    handle: {
      prefer: "left",
    },
    index: true,
  },
  {
    path: "/callback",
    element: <WaitForSuccessfulLogin />,
    handle: {
      prefer: "left",
    },
  },
  {
    path: Path.Login,
    element: <LoginPage />,
  },
  {
    path: Path.Error,
    element: <ErrorPage />,
  },
  {
    path: Path.SelectAccount,
    element: <SelectAccountPage />,
  },
  {
    path: "/mobile",
    element: (
      <AppWithSideMenu>
        <GetMobilePage />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/support",
    element: (
      <AppWithSideMenu>
        <FeedbackAndSupportPage />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/updates",
    element: (
      <AppWithSideMenu>
        <UpdatesPage />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
];

export const routes = [
  {
    path: "/",
    element: <AuthedRoute />,
    handle: {
      prefer: "left",
    },
    children: [
      ...mainRoutes,
      ...settingsRoutes,
      {
        path: "/games",
        element: <Outlet />,
        children: gameRoutes,
      },
      ...publicRoutes,
      ...doesntSupportPublisherView,
      ...publishersRoutes,
      ...documentRoutes,
      ...royaltyRoutes,
      ...messageBoardRoutes,
      ...playTestRoutes,
    ],
  },
];
