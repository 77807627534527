import React from "react";
import { FixedSizeList as List } from "react-window";
import { ListDetail } from "../web/ListDetail";
import { useFiltersv2 } from "../core/useFilters";
import { FilterList } from "../core/FilterList";
import { ListView } from "../core/ListView";

/**
 * @template T
 * @param {({ item, filteredIndex, filteredCount, style }) => React.JSX.Element} ListEntry
 * @param {(item: T) => string} keyExtractor
 * @param {string} title
 * @param {() => React.JSX.Element} EmptyList
 * @param {() => React.JSX.Element} [Action]
 * @param {() => React.JSX.Element|undefined} [ListHeader]
 * @param {import("react-router-dom").To} [back]
 * @param {boolean} [bigList]
 * @returns {(queryResult: import("@tanstack/react-query").UseQueryResult<T[]>, filterConfiguration: Filters<T>) => () => React.JSX.Element}
 */
export const useMakeListBuilder = (
  ListEntry,
  keyExtractor,
  title,
  EmptyList,
  Action,
  ListHeader,
  back,
  bigList = false
) => {
  /**
   * @template T
   * @param {import("@tanstack/react-query").UseQueryResult<T[]>} queryResult
   * @param {Filters<T>} filters
   * @returns {() => React.JSX.Element}
   */
  const useMakeList = (queryResult, filters) => {
    const Filters = () => (
      <FilterList filters={filters.visibleFilters} filter={filters.filter} onFilterClicked={filters.onFilterClicked} />
    );

    const ListEntryWrapper = ({ style, index, data, ...props }) => {
      console.log(props, index, data);
      const item = data[index];
      return <ListEntry item={item} filteredIndex={index} filteredCount={data.length} style={style} />;
    };

    return () => (
      <ListView
        back={back}
        title={title}
        Filters={Filters}
        Action={Action}
        EmptyList={EmptyList}
        shown={filters.filtered.length}
        refetch={queryResult.refetch}
        isLoading={queryResult.isLoading}
        isRefetching={queryResult.isRefetching}
        isError={queryResult.isError}
      >
        {ListHeader && <ListHeader />}
        {bigList ? (
          <List height={500} itemCount={filters.filtered.length} itemSize={60} itemData={filters.filtered}>
            {ListEntryWrapper}
          </List>
        ) : (
          filters.filtered.map((item, filteredIndex) => (
            <ListEntry
              // @ts-ignore
              key={keyExtractor(item)}
              item={item}
              filteredIndex={filteredIndex}
              filteredCount={filters.filtered.length}
              style={{}}
            />
          ))
        )}
      </ListView>
    );
  };

  return useMakeList;
};

/**
 * @param {object} item
 * @returns {string}
 */
export const idExtractor = (item) => item.id.toString();

/**
 * @template T
 * @param {() => import("@tanstack/react-query").UseQueryResult<T[]>} query
 * @param {Filter<T>[]} filters
 * @param {string} filterKey
 * @param {(queryResult: import("@tanstack/react-query").UseQueryResult<T[]>, filterConfiguration: Filters<T>) => () => React.JSX.Element} useMakeList
 * @param {() => React.JSX.Element} DetailView
 * @returns {() => React.JSX.Element}
 */
export const useMakeListAndDetail = (query, filters, filterKey, useMakeList, DetailView) => {
  const queryResult = query();
  const filterConfiguration = useFiltersv2(queryResult, filters, filterKey);
  const ListView = useMakeList(queryResult, filterConfiguration);

  return () => <ListDetail ListView={ListView} DetailView={DetailView} />;
};

/**
 * @template T
 * @param {() => import("@tanstack/react-query").UseQueryResult<T[]>} query
 * @param {Filter<T>[]} filters
 * @param {string} filterKey
 * @param {(queryResult: import("@tanstack/react-query").UseQueryResult<T[]>, filterConfiguration: Filters<T>) => () => React.JSX.Element} useMakeList
 * @returns {() => React.JSX.Element}
 */
export const useMakeListView = (query, filters, filterKey, useMakeList) => {
  const queryResult = query();
  const filterConfiguration = useFiltersv2(queryResult, filters, filterKey);
  const ListView = useMakeList(queryResult, filterConfiguration);

  return () => <ListView />;
};
