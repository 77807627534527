import React, { useMemo, useRef, useState } from "react";
import YooptaEditor, { createYooptaEditor } from "@yoopta/editor";
import Paragraph from "@yoopta/paragraph";
import Blockquote from "@yoopta/blockquote";
import Code from "@yoopta/code";
import Divider from "@yoopta/divider";
import Links from "@yoopta/link";
import Callout from "@yoopta/callout";
import { NumberedList, BulletedList, TodoList } from "@yoopta/lists";
import { Bold, Italic, CodeMark, Underline, Strike, Highlight } from "@yoopta/marks";
import { HeadingThree, HeadingTwo } from "@yoopta/headings";
import ActionMenu, { DefaultActionMenuRender } from "@yoopta/action-menu-list";
import "./editor.css";
import { useWindowSize } from "usehooks-ts";
import Toolbar, { DefaultToolbarRender } from "@yoopta/toolbar";
import LinkTool, { DefaultLinkToolRender } from "@yoopta/link-tool";
import Box from "@mui/material/Box";

const tools = {
  ActionMenu: {
    tool: ActionMenu,
    render: DefaultActionMenuRender,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
};

const plugins = [
  Paragraph,
  Blockquote,
  NumberedList,
  BulletedList,
  TodoList,
  HeadingTwo,
  HeadingThree,
  Links,
  Callout,
  Divider,
  Code.extend({
    elementProps: {
      code: (props) => ({
        ...props,
        theme: "GithubLight",
      }),
    },
  }),
];

const marks = [Bold, Italic, CodeMark, Underline, Strike, Highlight];

export const defaultState = /** @type {import("@seabrookstudios/pitch2table-core").BlockType} */ ({
  "95e9b5ab-2158-48da-9196-6079a33508f2": {
    id: "95e9b5ab-2158-48da-9196-6079a33508f2",
    value: [
      {
        id: "dac238c2-0db7-4a0d-9b4b-543e340f935b",
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
        props: {
          nodeType: "block",
        },
      },
    ],
    type: "Paragraph",
    meta: {
      order: 0,
      depth: 0,
    },
  },
});

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").BlockType} props.initialValue
 * @param {(value: import("@seabrookstudios/pitch2table-core").BlockType) => void} props.onChange
 */
export function BlockEditor({ initialValue = defaultState, onChange }) {
  const { height } = useWindowSize();
  const editor = useMemo(() => createYooptaEditor(), []);
  const selectionRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  // /**
  //  * @param {string} string
  //  */
  // const deserializeHTML = (string) => {
  //   const content = html.deserialize(editor, string);
  //   editor.setEditorValue(content);
  // };

  // useEffect(() => {
  //   if (!toMigrate) {
  //     return;
  //   }

  //   deserializeHTML(toMigrate);
  // }, []);

  // const serializeHTML = () => {
  //   try {
  //     const data = editor.getEditorValue();
  //     const htmlString = html.serialize(editor, data);
  //     console.log(htmlString);
  //     setBody(htmlString);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  /**
   * @param {import("@yoopta/editor").YooptaContentValue} value
   * @param {import("@yoopta/editor").YooptaOnChangeOptions} _options
   */
  const onChangeHandler = (value, _options) => {
    // console.log({ value, options });
    setValue(value);
    onChange(value);
    // serializeHTML(value);
  };

  return (
    <Box pl={4} pr={4} style={{ minHeight: height * 0.7 }}>
      <div ref={selectionRef}>
        <YooptaEditor
          placeholder="Type text"
          editor={editor}
          plugins={plugins}
          tools={tools}
          marks={marks}
          value={value}
          width={"66ch"}
          selectionBoxRoot={selectionRef}
          onChange={onChangeHandler}
          autoFocus
        />
      </div>
    </Box>
  );
}

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").BlockType} props.value
 */
export function ReadOnlyEditor({ value }) {
  const { height } = useWindowSize();
  const editor = useMemo(() => createYooptaEditor(), []);
  const selectionRef = useRef(null);

  return (
    <Box pl={4} pr={4} style={{ minHeight: height * 0.7 }}>
      <div ref={selectionRef}>
        <YooptaEditor
          placeholder="Type text"
          editor={editor}
          plugins={plugins}
          tools={tools}
          marks={marks}
          value={value}
          width={"66ch"}
          selectionBoxRoot={selectionRef}
          readOnly
          autoFocus
        />
      </div>
    </Box>
  );
}
