import React, { createContext, useCallback, useMemo } from "react";
import { s2PublisherId } from "../util/type-conversion";
import { useLocalStorage } from "usehooks-ts";

/**
 * @typedef {('Designer'|'Publisher')} Mode
 */

/**
 * @typedef {object} AccountProviderState
 * @property {Mode} state.mode
 * @property {import("@seabrookstudios/pitch2table-core").PublisherId} state.publisherId
 * @property {Function} state.setDesignerMode
 * @property {Function} state.setPublisherMode
 */

/**
 * @type {AccountProviderState}
 */
const initialState = {
  mode: "Designer",
  publisherId: s2PublisherId(""),
  setDesignerMode: () => console.log("AccountProvider not initialised yet."),
  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
   */
  setPublisherMode: (publisherId) => console.log("AccountProvider not initialised yet.", publisherId),
};

export const AccountContext = createContext(initialState);

/**
 * @param {object} props
 * @param {import('react').ReactNode} [props.children]
 */
const AccountProvider = ({ children }) => {
  /**
   * @type {[Mode,React.Dispatch<React.SetStateAction<Mode>>]}
   */
  const [mode, setMode] = useLocalStorage("com.pitch2table.account.mode", initialState.mode);
  /**
   * @type {[import("@seabrookstudios/pitch2table-core").PublisherId,React.Dispatch<React.SetStateAction<import("@seabrookstudios/pitch2table-core").PublisherId>>]}
   */
  const [publisherId, setPublisherId] = useLocalStorage(
    "com.pitch2table.account.publisherId",
    initialState.publisherId
  );

  const setDesignerMode = useCallback(() => {
    setMode("Designer");
  }, [setMode]);

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
   */
  const setPublisherMode = useCallback(
    (publisherId) => {
      setPublisherId(publisherId);
      setMode("Publisher");
    },
    [setPublisherId, setMode]
  );

  const value = useMemo(
    () => ({
      mode,
      publisherId,
      setDesignerMode,
      setPublisherMode,
    }),
    [mode, publisherId, setDesignerMode, setPublisherMode]
  );

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export default AccountProvider;
