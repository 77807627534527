import React from "react";
import { DateTime } from "luxon";
import { useGameIdFromRoute, usePlayTestIdFromRoute, usePlayTestMetaIdFromRoute } from "../games/WithGameIdFromRoute";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { PlayTestMediumLabels, PlayTestMetadataComponentTypeLabels } from "@seabrookstudios/pitch2table-core";
import Chip from "@mui/material/Chip";
import { useGame, useGamePlayTestMetaData, useGamePlayTests } from "@seabrookstudios/pitch2table";
import ListItem from "@mui/material/ListItem";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Stack from "@mui/material/Stack";
import { formatPlayerCount, formatPlayers, formatPlays, formatScores, formatWinner, usePlayTesters } from "./model";
import { Link as RouterLink } from "react-router-dom";
import CopyAll from "@mui/icons-material/CopyAll";
import { useCopyToClipboard } from "usehooks-ts";
import { enqueueSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PlayTest} props.item
 * @param {React.CSSProperties|undefined} props.style
 */
export const PlayTestListEntry = ({ item, style }) => {
  const gameId = useGameIdFromRoute();
  const playTestId = usePlayTestIdFromRoute();
  const { data: metadata = [] } = useGamePlayTestMetaData(gameId);

  const navigate = useNavigate();

  const selected = playTestId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).test(item.id));
  };

  return (
    <ListItemButton
      style={style}
      id={`play-test-${item.id}`}
      alignItems="flex-start"
      selected={selected}
      onClick={onClickHandler}
    >
      <ListItemAvatar>
        <Stack direction="column">
          <Typography textAlign="left" variant="body2">
            {DateTime.fromMillis(item.date).toFormat("dd MMM")}
          </Typography>
          <Typography textAlign="left" variant="body1">
            {DateTime.fromMillis(item.date).toFormat("yyyy")}
          </Typography>
        </Stack>
      </ListItemAvatar>
      <ListItemText primary={formatPlayers(item)} secondary={`${formatWinner(item)} ${formatScores(item, metadata)}`} />
      <ListItemSecondaryAction>
        <Chip label={formatPlayerCount(item.outcomes.length)} />
        <Chip label={PlayTestMediumLabels[item.medium]} />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestForApp} props.item
 * @param {React.CSSProperties|undefined} props.style
 */
export const PlayTestSummaryListEntry = ({ item, style }) => {
  const { data: game } = useGame(item.gameId);

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Path.game(item.gameId).test(item.id));
  };

  return (
    <ListItemButton style={style} id={`play-test-${item.id}`} alignItems="flex-start" onClick={onClickHandler}>
      <ListItemAvatar>
        <Stack direction="column">
          <Typography textAlign="left" variant="body2">
            {DateTime.fromMillis(item.date).toFormat("dd MMM")}
          </Typography>
          <Typography textAlign="left" variant="body1">
            {DateTime.fromMillis(item.date).toFormat("yyyy")}
          </Typography>
        </Stack>
      </ListItemAvatar>
      <ListItemText primary={game ? game.name : ""} secondary={item.playerList} />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponent} props.item
 */
export const PlayTestMetadataListEntry = ({ item }) => {
  const gameId = useGameIdFromRoute();
  const metaId = usePlayTestMetaIdFromRoute();

  const navigate = useNavigate();

  const selected = metaId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).metadataEntry(item.id));
  };

  return (
    <ListItemButton id={`play-test-${item.id}`} alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <ListItemText primary={item.order} />
      </ListItemAvatar>
      <ListItemText primary={item.name} secondary={PlayTestMetadataComponentTypeLabels[item.type]} />
      {item.archived && (
        <ListItemSecondaryAction>
          <Chip label="ARCHIVED" />
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
};

const nothingTracked = "Nothing tracked. Track important aspects of your game to see how they change over time.";

export const PlayTestMetaData = () => {
  const gameId = useGameIdFromRoute();
  const { data: metadata = [] } = useGamePlayTestMetaData(gameId);
  const { data: playTests = [] } = useGamePlayTests(gameId);

  return (
    <>
      <ListItem>
        <ListItemText
          primary="Tracking Data"
          secondary={metadata.length === 0 ? nothingTracked : metadata.map((m) => m.name).join(", ")}
        />
        <ListItemSecondaryAction>
          <ListItemButton to={Path.game(gameId).metadata()} component={RouterLink}>
            <ArrowForward />
          </ListItemButton>
        </ListItemSecondaryAction>
      </ListItem>
      {playTests.length > 0 && (
        <ListItem>
          <ListItemText primary="Charts" secondary="View play test data as charts" />
          <ListItemSecondaryAction>
            <ListItemButton to={Path.game(gameId).charts()} component={RouterLink}>
              <ArrowForward />
            </ListItemButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {playTests.length > 0 && <PlayTesterCopyAll />}
      <Divider />
    </>
  );
};

export const PlayTesterCopyAll = () => {
  const gameId = useGameIdFromRoute();
  const { data: players = [] } = usePlayTesters(gameId);
  const [, copy] = useCopyToClipboard();

  const copyNamesToClipboard = () => {
    copy(players.map((p) => p.name).join(", "));
    enqueueSnackbar("Copied.", { variant: "success" });
  };

  return (
    <ListItem>
      <ListItemText primary="All Play Testers (alphabetically)" secondary={players.map((p) => p.name).join(", ")} />
      <ListItemSecondaryAction>
        <Tooltip title="copy names to clipboard">
          <ListItemButton onClick={copyNamesToClipboard}>
            <CopyAll />
          </ListItemButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

/**
 * @param {object} props
 * @param {import("./model").PlayTesterSummary} props.item
 */
export const PlayTesterEntry = ({ item }) => {
  return (
    <ListItem id={`play-tester-${item.id}`} alignItems="flex-start">
      <ListItemText primary={item.name} secondary={formatPlays(item.count)} />
    </ListItem>
  );
};
