import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

/**
 * @param {object} props
 * @param {string} [props.id]
 * @param {string} [props.value]
 * @param {string} [props.initialValue]
 * @param {(text: (string)) => void} props.onChange
 * @param {(text: (string)) => void} [props.onEnter]
 * @param {boolean} [props.onEnterDisabled]
 * @param {boolean} [props.multiline]
 * @param {boolean} [props.autoFocus]
 * @param {number} [props.minRows]
 * @param {boolean} [props.required]
 * @param {string} [props.label]
 * @param {string} [props.helperText]
 * @param {("text"|"number")} [props.type]
 * @param {("left"|"right")} [props.textAlign]
 * @param {("small"|"medium")} [props.size]
 * @param {boolean} [props.borderless]
 */
export const SmartTextField = ({
  id,
  initialValue = "",
  value,
  onChange,
  onEnterDisabled = true,
  onEnter,
  multiline = false,
  autoFocus = false,
  required = false,
  helperText = "",
  minRows = 1,
  label,
  type = "text",
  textAlign = "left",
  size = "medium",
  borderless = false,
  ...props
}) => {
  const [name, setName] = useState(initialValue);
  const [changed, setHasChanged] = useState(false);
  const [isValid, setValidStatus] = useState(true);

  const onChangeHandler = (ev) => {
    setHasChanged(true);
    setName(ev.target.value);
  };

  const onKeyDownHandler = (ev) => {
    if (onEnterDisabled) {
      return;
    }
    if (ev.key !== "Enter") {
      return;
    }

    if (onEnter) {
      onEnter(name);
    }
  };

  const validate = useCallback(() => {
    if (`${name}`.length === 0) {
      onChange("");
      setValidStatus(false);
      return false;
    }

    setValidStatus(true);
    onChange(name);
    return true;
  }, [setValidStatus, name, onChange]);

  useEffect(() => {
    if (!changed) {
      return;
    }

    validate();
  }, [name, validate, changed]);

  return (
    <TextField
      id={id}
      defaultValue={initialValue}
      fullWidth
      variant={borderless ? "standard" : "outlined"}
      type={type}
      margin="dense"
      multiline={multiline}
      minRows={minRows}
      autoFocus={autoFocus}
      label={borderless ? helperText : label}
      size={size}
      required={required}
      // helperText={helperText}
      {...props}
      error={required && !isValid}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      sx={{ "& input": { textAlign } }}
    />
  );
};
