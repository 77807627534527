import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Cached from "@mui/icons-material/Cached";
import { useDocumentTitle } from "usehooks-ts";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";

const Error = () => {
  return <Alert severity="error">An error occurred</Alert>;
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.JSX.Element|import("react").ReactNode|null} [props.children]
 * @param {(() => React.JSX.Element)|null} props.Filters
 * @param {(() => React.JSX.Element)|undefined} [props.Action]
 * @param {import("react-router-dom").To|null} [props.back]
 * @param {number} [props.shown]
 * @param {() => React.JSX.Element} [props.EmptyList]
 * @param {boolean} [props.neverEmpty]
 * @param {Function} [props.refetch]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isRefetching]
 * @param {boolean} [props.isError]
 */
export const ListView = ({
  title,
  children,
  Filters = null,
  Action = undefined,
  back = null,
  shown = 0,
  EmptyList = () => <Box />,
  neverEmpty = false,
  refetch,
  isLoading = false,
  isRefetching = false,
  isError = false,
}) => {
  const clickHandler = () => {
    if (!refetch) {
      return;
    }

    refetch();
  };

  const NoData = isError ? Error : EmptyList;
  const postLoad = (shown > 0 || neverEmpty) && !isLoading ? children : <NoData />;

  useDocumentTitle(`Pitch2Table - ${title}`);

  return (
    <Paper elevation={1} square={false}>
      <Stack direction="row" justifyContent="center">
        <Box flex={1} pl={2} pt={1} justifyContent="flex-start" alignItems="center" display="flex">
          {back && (
            <Link to={back} component={RouterLink} color="inherit">
              <ArrowBack />
            </Link>
          )}
        </Box>
        <Typography variant="h5" textAlign="center" p={2}>
          {title}
        </Typography>
        <Box flex={1} pt={2} alignItems="flex-start" justifyContent="flex-end" display="flex">
          {refetch && (
            <IconButton id="refetch" color="primary" onClick={clickHandler}>
              {isRefetching ? <CircularProgress size={16} /> : <Cached />}
            </IconButton>
          )}
          {Action && <Action />}
        </Box>
      </Stack>
      {Filters && (
        <Stack direction="row" spacing={1} justifyContent="center" p={1} flexWrap="wrap">
          <Filters />
        </Stack>
      )}
      <List>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          postLoad
        )}
      </List>
    </Paper>
  );
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.JSX.Element|import("react").ReactNode|null} props.children
 * @param {(() => React.JSX.Element)|undefined} [props.Action]
 * @param {import("react-router-dom").To|null} [props.back]
 * @param {boolean} [props.neverEmpty]
 * @param {Function} [props.refetch]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isRefetching]
 */
export const SinglePageView = ({
  title,
  children,
  Action = undefined,
  back = null,
  refetch,
  isLoading = false,
  isRefetching = false,
}) => {
  const clickHandler = () => {
    if (!refetch) {
      return;
    }

    refetch();
  };

  useDocumentTitle(`Pitch2Table - ${title}`);

  return (
    <Paper elevation={1} square={false}>
      <Stack direction="row" justifyContent="center">
        <Box flex={1} pl={2} pt={1} justifyContent="flex-start" alignItems="center" display="flex">
          {back && (
            <Link to={back} component={RouterLink} color="inherit">
              <ArrowBack />
            </Link>
          )}
        </Box>
        <Typography variant="h5" textAlign="center" p={2}>
          {title}
        </Typography>
        <Box flex={1} pt={2} alignItems="flex-start" justifyContent="flex-end" display="flex">
          {refetch && (
            <IconButton id="refetch" color="primary" onClick={clickHandler}>
              {isRefetching ? <CircularProgress size={16} /> : <Cached />}
            </IconButton>
          )}
          {Action && <Action />}
        </Box>
      </Stack>
      <List>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </List>
    </Paper>
  );
};
