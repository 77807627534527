import React from "react";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import { useWarnings } from "@seabrookstudios/pitch2table";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";

export const WarningBanner = () => {
  const gameId = useGameIdFromRoute();
  const warnings = useWarnings(gameId);

  if (warnings.length === 0) {
    return <Box />;
  }

  return (
    <NoItemsFound>
      <Box flexDirection="column">
        {warnings.map((warning) => (
          <Box key={warning.text}>
            <Alert severity="warning">{warning.text}</Alert>
            <Box p={1} />
          </Box>
        ))}
      </Box>
    </NoItemsFound>
  );
};
