import { useQuery } from "@tanstack/react-query";
import { DefaultStaleTime } from "../core/react-query";
import { ThisUser } from "../games/query-keys";
import { fetchThisUser } from "../users/users-service";
import { useContext } from "react";
import { CachedTokenContext } from "@seabrookstudios/auth";

export const useUserProfile = () => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: ThisUser(),
    queryFn: async () => {
      const data = await fetchThisUser(token);
      return data;
    },
    staleTime: DefaultStaleTime,
  });
};
