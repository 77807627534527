import React from "react";
import "./editor.css";
import Typography from "@mui/material/Typography";
import { domToReact } from "html-react-parser";
import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { HtmlEditor } from "./HtmlEditor";
import { Features } from "../games/features";
import { BlockEditor } from "./BlockEditor";

/**
 * @param {object} props
 * @param {string|import("@seabrookstudios/pitch2table-core").BlockType} props.value
 * @param {function} props.onChange
 * @param {import("@seabrookstudios/pitch2table-core").EditorImplementations} props.implementation
 */
export const Editor = ({ implementation, value, onChange }) => {
  if (implementation === "Block") {
    return <BlockEditor initialValue={value} onChange={onChange} />;
  }
  if (implementation === "HTML") {
    return <HtmlEditor value={value} onChange={onChange} />;
  }

  return Features.BlockEditor ? (
    <BlockEditor initialValue={value} onChange={onChange} />
  ) : (
    <HtmlEditor value={value} onChange={onChange} />
  );
};

/**
 * @type {import("html-react-parser").HTMLReactParserOptions}
 */
export const options = {
  replace: ({ attribs, name, children }) => {
    if (!attribs) {
      return;
    }

    if (name === "h1") {
      return (
        <Typography variant="h1" {...attribs} style={{ fontSize: 30 }}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h2") {
      return (
        <Typography variant="h2" {...attribs} style={{ fontSize: 24 }}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h3") {
      return (
        <Typography variant="h3" {...attribs} style={{ fontSize: 20 }}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h4") {
      return (
        <Typography variant="h4" {...attribs} style={{ fontSize: 18 }}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h5") {
      return (
        <Typography variant="h5" {...attribs} style={{ fontSize: 16 }}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h6") {
      return (
        <Typography variant="h6" {...attribs} style={{ fontSize: 15 }}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "p") {
      return (
        <Typography whiteSpace="pre-wrap" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "a") {
      return (
        <Link {...attribs} rel="noopener" target="_blank" underline="always" display="flex" alignItems="center">
          {domToReact(children, options)}
          &nbsp;
          <OpenInNew fontSize="small" />
        </Link>
      );
    }
    if (name === "strong") {
      return <strong {...attribs}>{domToReact(children, options)}</strong>;
    }
    if (name === "em") {
      return <em {...attribs}>{domToReact(children, options)}</em>;
    }
    if (name === "u") {
      return <u {...attribs}>{domToReact(children, options)}</u>;
    }
    if (name === "s") {
      return <s {...attribs}>{domToReact(children, options)}</s>;
    }
    if (name === "blockquote") {
      return <blockquote {...attribs}>{domToReact(children, options)}</blockquote>;
    }
    if (name === "ol") {
      return (
        <List sx={{ listStyle: "decimal", pl: 4 }} {...attribs}>
          {domToReact(children, options)}
        </List>
      );
    }
    if (name === "ul") {
      return (
        <List sx={{ listStyleType: "disc", pl: 4 }} {...attribs}>
          {domToReact(children, options)}
        </List>
      );
    }
    if (name === "li") {
      return (
        <ListItem sx={{ display: "list-item" }} {...attribs}>
          {domToReact(children, options)}
        </ListItem>
      );
    }
    if (name === "br") {
      return <br {...attribs} />;
    }

    return <span>{domToReact(children, options)}</span>;
  },
};
