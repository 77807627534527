import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import Check from "@mui/icons-material/Check";
import Warning from "@mui/icons-material/Warning";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../../Path";
import { useWarnings } from "@seabrookstudios/pitch2table";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Game} props.game
 */
export const ContractCardForGame = ({ game }) => {
  const warnings = useWarnings(game.id);

  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <ListItemText primary="Contracts" secondary={warnings.length > 0 ? "Uploaded" : "Missing contracts!"} />
          <ListItemIcon>
            <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
              {warnings.length === 0 ? (
                <Chip icon={<Check />} label="DONE" color="success" />
              ) : (
                <Chip icon={<Warning />} label="MISSING" color="warning" />
              )}
            </Box>
          </ListItemIcon>
          <Link to={Path.game(game.id).contracts()} component={RouterLink}>
            <ListItemIcon>
              <ListItemButton>
                <ArrowForwardOutlined />
              </ListItemButton>
            </ListItemIcon>
          </Link>
        </ListItem>
      </List>
    </Card>
  );
};
